import { useSWRConfig } from 'swr'
import client from '~/client'

export default function useMutateUsers() {
  const { mutate } = useSWRConfig()
  const userInfo = client.getUserInfo()

  return () =>
    mutate(
      key =>
        typeof key === 'string' &&
        [
          `companyusers`,
          'protected',
          'distributorCompanyRelations/users',
          'distributorCompanyRelations/contractors',
        ].some(string => key.startsWith(string)),
      undefined,
      { revalidate: true }
    )
}
