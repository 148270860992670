import { Link, PlaylistAdd, Search } from '@mui/icons-material'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import Drawer from '~/components/Layouts/Drawer'
import useSearchParams from '~/core/router/useSearchParams'
import useMutateCompanies from '../../../../components/Companies/useMutateCompanies'

export default function CreatePairingModal({ mode = 'distributor' }) {
  const userInfo = client.getUserInfo()

  // const isAdmin = userInfo.role.includes('admin')
  const isAdmin = client.hasRoles(['rep_admin', 'rep_sales_manager'])

  const entityType =
    mode === 'distributor'
      ? { name: 'distributor', title: 'distributor' }
      : { name: 'contractingFirm', title: 'contracting firm' }
  const [relationshipPinCode, setRelationshipPinCode] = React.useState('')
  const [company, setCompany] = React.useState(null)
  const [isChecking, setIsChecking] = React.useState(false)

  const handleChange = e => {
    setCompany(null)
    setRelationshipPinCode(e.target.value)
  }

  const mutate = useMutateCompanies()

  // const { mutate, ...response } = usePageQuery(
  //   `companyRelations/${userInfo.companyId}`
  // )

  const handleCheckClick = async () => {
    try {
      setIsChecking(true)
      const res = await client.get(
        `companyRelations/?pinCode=${relationshipPinCode}`
      )
      console.log(res)
      setCompany(res)
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsChecking(false)
    }
  }

  const [isAdding, setIsAdding] = React.useState(false)
  const handleAddClick = async () => {
    try {
      setIsAdding(true)
      const res = await client.call('companyRelations', {
        relationshipPinCode,
      })
      mutate()
      setRelationshipPinCode('')
      setCompany(null)
      enqueueSnackbar(
        `${res.companyName || 'Company'} added as a ${entityType.title}`,
        {
          variant: 'success',
        }
      )
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsAdding(false)
    }
  }

  // const handleDelete = async ({ companyRelationId }) => {
  //   console.log('handleDelete', companyRelationId)
  //   await client.put(`companyRelations`, {
  //     companyRelationId,
  //     status: 'disabled',
  //   })
  //   mutate()
  // }

  // const handleEdit = async ({ companyRelationId }) => {
  //   const input = prompt('Enter discount %')
  //   const discount = parseFloat(input)

  //   if (input === null) return

  //   if (isNaN(discount) || discount > 100 || discount < 0) {
  //     enqueueSnackbar('Invalid discount', { variant: 'error' })
  //     return
  //   }

  //   const res = await client.put('companyRelations', {
  //     companyRelationId,
  //     discount,
  //   })
  //   mutate()

  //   console.log('companyRelations res', res)
  //   enqueueSnackbar('Discount updated', { variant: 'success' })
  // }

  const [params, setParams] = useSearchParams()

  return (
    <>
      <Button
        endIcon={<Link />}
        onClick={() => setParams(p => ({ ...p, pair: true }))}
      >
        Pair with existing {entityType.title}
      </Button>

      <Drawer open={params.hasOwnProperty('pair')}>
        <LoadingIndicators {...{ isLoading: isChecking || isAdding }} />

        <Typography variant="h4" sx={{ mb: 2 }}>
          Pair with {entityType.title}
        </Typography>

        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: 'flex-start', mb: 3 }}
        >
          <Typography>
            Speak to your {entityType.title} to get their code. Enter the code
            below to access their pricing and support.
          </Typography>
          <TextField
            // type="number"
            title={`${entityType.title} code`}
            name="relationshipPinCode"
            value={relationshipPinCode}
            onChange={handleChange}
          />
          <SubmitButton
            isSubmitting={isChecking}
            onClick={handleCheckClick}
            disabled={relationshipPinCode.length < 6}
            variant="outlined"
            endIcon={<Search />}
          >
            Check for {entityType.title}
          </SubmitButton>
        </Stack>

        {company && (
          <Stack>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {company.companyName}
            </Typography>

            <Typography sx={{ mb: 2 }}>
              Address:
              {[
                company.address?.line1,
                company.address?.state,
                company.address?.zipCode,
              ].join(', ')}
            </Typography>

            {company.website && (
              <Typography sx={{ mb: 2 }}>
                Website:{' '}
                <a href={`https://${company.website}`} target="blank">
                  {company.website}
                </a>
              </Typography>
            )}

            {company?.companyType === mode ? (
              <SubmitButton
                isSubmitting={isAdding}
                onClick={handleAddClick}
                endIcon={<PlaylistAdd />}
              >
                Add {entityType.title}
              </SubmitButton>
            ) : (
              <Typography>
                This company isn't a {entityType.title} so you can't add it.
              </Typography>
            )}
          </Stack>
        )}
      </Drawer>
    </>
  )
}
