import { FormContainer, useForm } from 'react-hook-form-mui'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import debounce from 'lodash.debounce'
import React from 'react'
import formatSearchObject from './formatSearchObject'

export default function AutoTableSearchForm({
  children,
  setSearch,
  defaultValues,
}) {
  const formContext = useForm({ defaultValues, shouldUnregister: false })

  const handleSuccess = data => setSearch(formatSearchObject(data))
  const debouncedHandleSuccess = debounce(handleSuccess, 500, {
    leading: true,
  })

  // submit on change
  const { handleSubmit, watch } = formContext
  React.useEffect(() => {
    const subscription = watch(handleSubmit(debouncedHandleSuccess))
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  // const containerRef = React.useRef()
  // React.useEffect(() => {
  //   if (containerRef.current)
  //     containerRef.current.querySelector('input[type=text]')?.focus()
  // }, [containerRef.current])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormContainer formContext={formContext}>{children}</FormContainer>
    </LocalizationProvider>
  )
}
