import { Link as MuiLink } from '@mui/material'

export default function LinkToModelNumber({ modelNumber, noWrap, ...rest }) {
  return (
    <MuiLink
      href={`/admin/product-catalogue/?modelNumber=${modelNumber}`}
      target="_blank"
      {...rest}
    >
      {modelNumber.replace(/-/g, '‑')}
    </MuiLink>
  )
}
