import React from 'react'

export const getRangeFromDays = (days = 7) => {
  const today = new Date()
  today.setHours(23, 59, 59, 999)
  const previous = new Date()
  previous.setDate(today.getDate() - days)
  previous.setHours(0, 0, 0, 0)
  return [
    previous, //.toISOString().split('T')[0],
    today, //.toISOString().split('T')[0],
  ]
}

const useDateRanges = () => {
  const [dates, setDates] = React.useState(getRangeFromDays(30))

  const setDatesFromDays = days => setDates(getRangeFromDays(days))

  const setStartDate = date => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    setDates(([start, end]) => [newDate, end])
  }

  const setEndDate = date => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59, 999)
    setDates(([start, end]) => [start, newDate])
  }

  const isDateSelected = days =>
    getRangeFromDays(days).every(
      (value, index) => dates[index].toISOString() === value.toISOString()
    )

  return {
    dates,
    isDateSelected,
    setDatesFromDays,
    setStartDate,
    setEndDate,
    startDate: dates[0]?.toISOString(),
    endDate: dates[1]?.toISOString(),
  }
}

export default useDateRanges
