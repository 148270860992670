import * as React from 'react'

import { enqueueSnackbar } from 'notistack'
import useSWR from 'swr'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'

const useTextTemplates = ({ search } = {}) => {
  const { mutate, ...response } = usePageQuery('proposalTemplate', { search })

  const [isDeleting, setIsDeleting] = React.useState(false)

  const deleteTemplate = async ({
    proposalTemplateId,
    name = 'this template',
  }) => {
    if (!window.confirm(`Are you sure you want to delete ${name}?`)) return
    try {
      setIsDeleting(true)
      mutate(
        client.delete(
          `proposalTemplate?proposalTemplateId=${proposalTemplateId}`
        ),
        {
          throwOnError: true,
          populateCache: false,
          optimisticData: templates =>
            templates.filter(
              template => template.proposalTemplateId !== proposalTemplateId
            ),
        }
      )
      enqueueSnackbar('Template deleted', { variant: 'success' })
    } finally {
      setIsDeleting(false)
    }
  }

  const [isSaving, setIsSaving] = React.useState(false)

  const saveTemplate = async data => {
    try {
      setIsSaving(true) // id?
      await mutate(client.put('proposalTemplate', data), {
        throwOnError: true,
        populateCache: false,
        optimisticData: templates =>
          data.proposalTemplateId
            ? templates.map(template =>
                template.proposalTemplateId === data.proposalTemplateId
                  ? data
                  : template
              )
            : [...templates, data],
      })
      enqueueSnackbar('Template saved', { variant: 'success' })
    } finally {
      setIsSaving(false)
    }
  }
  const isSubmitting = isSaving || isDeleting

  return { ...response, isSubmitting, saveTemplate, deleteTemplate }
}

export default useTextTemplates
