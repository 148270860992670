import { Card, Grid, Typography } from '@mui/material'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Box } from '@mui/system'
import { Outlet } from 'react-router-dom'
import client from '~/client'
import PageHeader from '~/components/Layouts/PageHeader'
import Chip from '~/components/Data/Chip'
import sharedProps from '~/components/sharedProps'
import CompanyDetailsForm from './CompanyDetailsForm'
import { formatDate } from '~/core/utils/formatDate'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import PreviewDialogs from '~/routes/Admin/Company/Profile/PreviewDialogs'
import CompanyLogoUpload from '~/routes/Admin/Company/Profile/CompanyLogoUpload'

export default function CompanyProfile({ companyId }) {
  const userInfo = client.getUserInfo()
  const { data, error, isLoading } = useCompanyDetails({ companyId })

  console.log('userInfo', { userInfo, data })
  return (
    <>
      <PageHeader />
      <Grid
        container
        spacing={3}
        // justify="center"
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2.5 }}>
            <LoadingIndicators isLoading={isLoading} />
            <Typography variant="h3" sx={{ mb: 2 }}>
              {data?.companyName || 'Overview'}
            </Typography>
            <Box {...sharedProps.pageInfo}>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Created date
                </Typography>
                <Typography>{formatDate(data?.createdAt)}</Typography>
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Chip size="small" label="Active" color="success" />
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Relationship PIN code
                </Typography>
                <Typography>{data?.relationshipPinCode}</Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card {...sharedProps.card}>
            <CompanyDetailsForm companyId={companyId} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: sharedProps.card.sx.p, mb: 2.5 }}>
            <CompanyLogoUpload companyId={companyId} />
          </Card>
        </Grid>
      </Grid>

      <Outlet />
    </>
  )
}
