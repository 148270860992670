import { enqueueSnackbar } from 'notistack'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'

export default function useNotifications({ search } = {}) {
  const userInfo = client.getUserInfo()

  const { mutate, ...rest } = usePageQuery('notification', { search })
  const updateRead = ({ notificationId, dateRead }) => {
    mutate(client.put('notification', { notificationId, dateRead }), {
      optimisticData: data =>
        data.map(currentRow =>
          currentRow.notificationId === notificationId
            ? { ...currentRow, dateRead }
            : currentRow
        ),
      // populateCache: (newMessage, messages) =>
      //   // console.log('populateCache', { data, arg: arg2 }) || false,
      //   messages?.map(message =>
      //     message.dateSent === newMessage.dateSent ? newMessage : message
      //   ) || [],
      populateCache: false,
    })
  }

  const deleteNotification = ({ dateSent }) => {
    mutate(
      client
        .delete(`notification`, {
          username: userInfo.email,
          dateSent,
        })
        .then(res => enqueueSnackbar('Message deleted')),
      {
        optimisticData: data =>
          data.filter(currentRow => currentRow.dateSent !== dateSent),
        populateCache: false,
      }
    )
  }

  return {
    mutate,
    ...rest,
    updateRead,
    deleteNotification,
  }
}
