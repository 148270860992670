import client from '../../../client'
import usePageQuery from '../../../client/usePageQuery'

export default function useDistributorEstimates(distributorId, options) {
  const userInfo = client.getUserInfo()
  const companyId = distributorId || userInfo.companyId

  const res = usePageQuery(
    `distributorEstimates?distributorId=${companyId}`,
    options
  )

  return res
}
