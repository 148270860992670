import { People } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import useCompanies from '~/components/Companies/useCompanies'
import AutoTable, {
  ChipCell,
  DisableCell,
  EditCell,
  TableCell,
} from '~/components/Data/AutoTable/AutoTable'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import AutoSearchMultiCheck from '../Data/AutoTable/AutoSearchMultiCheck'
import { companyStatusOptions } from '../Data/AutoTable/statusOptions'

export default function CompaniesTable({ query }) {
  const [search, setSearch] = React.useState()
  const { deleteCompany, ...response } = useCompanies({ query, search })

  return (
    <AutoTable
      appStyles
      {...response}
      setSearch={setSearch}
      titleField="companyName"
      uniqueField="companyId"
      columns={[
        'companyName',
        'markupRate',
        // 'distributorMultiplier',
        'relationshipPinCode',
        {
          field: 'distributorCompany.companyName',
          headerName: 'Distro parent',
          Component: ({ data, ...rest }) => (
            <TableCell sx={{ px: '0 !important' }}>
              {data?.distributorCompany?.companyName}
            </TableCell>
          ),
        },
        { field: 'companyType', Component: ChipCell },
        'address.zipCode',
        {
          field: 'status',
          Component: ChipCell,
          headerSearch: <AutoSearchMultiCheck options={companyStatusOptions} />,
        },
        {
          field: 'edit',
          Component: EditCell,
        },
        {
          field: 'editUsers',
          Component: ({ data, ...rest }) => (
            <TableCell sx={{ px: '0 !important' }}>
              <Link to="../users" state={data}>
                <Button {...sharedProps.button.table} startIcon={<People />}>
                  Users
                </Button>
              </Link>
            </TableCell>
          ),
        },
        {
          field: 'disable',
          Component: DisableCell,
          width: 0.5,
          onClick: deleteCompany,
        },
      ]}
    />
  )
}
