import {
  Button,
  Card,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material'
import PageHeader from '~/components/Layouts/PageHeader'

// import { getProposals } from '../FastQuote/Provider/proposalManager'
import {
  Add as AddIcon,
  Edit as EditIcon,
  PostAdd as PostAddIcon,
  Share as ShareIcon,
  RemoveRedEye as ViewIcon,
} from '@mui/icons-material'

import React from 'react'
import client from '~/client'
import AutoTable, {
  ChipCell,
  DateCell,
  TableCell,
} from '~/components/Data/AutoTable/AutoTable'
import MenuCell from '~/components/Data/MenuCell'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import ProposalExportDialog, {
  completedProposalExportFields,
} from '~/routes/Admin/Proposals/ProposalExportDialog'
import ProposalStatusDialog from '~/routes/Admin/Proposals/ProposalStatusDialog'
import { statusElementsArray } from '~/routes/Admin/Proposals/statusUtils'

import useProposals from '~/routes/Admin/Proposals/useProposals'
import AutoSearchMultiCheck from '../../../components/Data/AutoTable/AutoSearchMultiCheck'
import { sxAppStylesFlex } from '../../../components/Layouts/AppStyles'
import { proposalStatusOptions } from '../../../components/Data/AutoTable/statusOptions'
import addressToArray from '../../ViewProposal/addressToArray'
import AutoSearchTextField from '../../../components/Data/AutoTable/AutoSearchTextField'

export default function Proposals() {
  // const loaderData = useLoaderData()
  const userInfo = client.getUserInfo()

  const [search, setSearch] = React.useState()

  const { handleDelete, updateStatus, ...response } = useProposals({ search })

  const [proposalExport, setProposalExport] = React.useState()
  const [proposalStatus, setProposalStatus] = React.useState()

  return (
    <>
      <PageHeader />

      <ProposalExportDialog
        proposal={proposalExport}
        onClose={() => setProposalExport(null)}
      />

      <ProposalStatusDialog
        proposal={proposalStatus}
        onClose={() => setProposalStatus(null)}
        newStatus={proposalStatus?.newStatus}
        updateStatus={updateStatus}
      />

      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          mb: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Link to="/admin/fast-quote">
          <Button
            variant="outlined"
            endIcon={<PostAddIcon />}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            New proposal
          </Button>
          <IconButton
            color="primary"
            // variant="filled"
            sx={{ display: { xs: 'flex', sm: 'none' } }}
          >
            <AddIcon />
          </IconButton>
        </Link>
      </Stack>

      <Card {...sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          {...response}
          uniqueField="proposalId"
          columns={[
            // 'proposalId',
            {
              field: 'createdAt',
              headerName: 'Created',
              Component: DateCell,
              breakpoints: ['sm'],
            },
            {
              // field: 'contents.$-customer-name.string_contains',
              field: 'contents.$-customer-name.string_contains',
              headerName: 'Customer name',
              breakpoints: ['sm'],

              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>{data.contents?.customer?.name}</TableCell>
              ),
            },

            {
              headerName: 'Address',
              field: 'contents.$-customer-address-zipCode.string_contains',
              headerSearch: <AutoSearchTextField placeholder="ZIP code" />,
              Component: ({ data, ...rest }) => (
                <TableCell {...rest}>
                  {addressToArray(data.contents?.customer?.address).join(', ')}
                  {/* {data.contents?.customer?.address?.line1},{' '}
                  {data.contents?.customer?.address?.city},{' '}
                  {data.contents?.customer?.address?.state}, */}
                </TableCell>
              ),
            },
            { field: 'customerEmail', heading: true },
            {
              field: 'status',
              headerName: 'Status',
              headerSearch: (
                <AutoSearchMultiCheck options={proposalStatusOptions} />
              ),
              sx: { width: 160 },
              Component: ({ data, ...rest }) => (
                <ChipCell {...rest}>{data.status}</ChipCell>
              ),
            },
            // {
            //   field: 'sentDate',
            //   headerName: 'Sent at',
            //   Component: DateCell,
            // },
            // {
            //   headerName: 'Total price',
            //   Component: ({ data, ...rest }) => (
            //     <TableCell {...rest}>
            //       {data.status === 'sent' &&
            //         usd.format(data.contents?.totalPrice)}
            //     </TableCell>
            //   ),
            // },
            {
              field: 'edit',
              Component: ({ data, ...rest }) =>
                data.status === 'draft' && userInfo.userId === data.userId ? (
                  <TableCell sx={{ px: '0 !important' }}>
                    <Link to="/admin/fast-quote/details" state={data}>
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                ) : (
                  <TableCell sx={{ px: '0 !important' }}>
                    <Link
                      to={`/view-proposal/${data.proposalId}`}
                      state={data}
                      target="view-proposal"
                    >
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<ViewIcon />}
                        color="success"
                      >
                        View
                      </Button>
                    </Link>
                  </TableCell>
                ),
            },
            // {
            //   field: 'viewMaterials',
            //   headerName: '',
            //   Component: ({ data, ...rest }) => (
            //     <TableCell sx={{ px: '0 !important' }}>
            //       <ProposalExportButton
            //         {...sharedProps.button.table}
            //         // startIcon={<ImportExport />}
            //         proposal={data}
            //       />
            //     </TableCell>
            //   ),
            // },
            // {
            //   field: 'delete',
            //   Component: ({ data, ...rest }) =>
            //     data.status === 'draft' ? (
            //       <TableCell sx={{ px: '0 !important' }}>
            //         <Button
            //           {...sharedProps.button.table}
            //           onClick={() => handleDelete(data)}
            //           color="error"
            //           startIcon={<DeleteOutlineIcon />}
            //         >
            //           Delete
            //         </Button>
            //       </TableCell>
            //     ) : (
            //       <TableCell />
            //     ),
            // },
            {
              field: 'actions',
              Component: ({ data, ...rest }) => (
                <MenuCell>
                  {/* <Box sx={{ mb: 1 }}>
                        <Typography sx={{ p: 2, pt: 1, fontWeight: 'bold' }}>
                          Proposal actions
                        </Typography>
                        <Divider />
                      </Box> */}
                  {/* <MenuItem onClick={() => console.log(data)}>
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          <DuplicateIcon />
                        </ListItemIcon>
                        <ListItemText>Duplicate</ListItemText>
                      </MenuItem> */}
                  <MenuItem
                    disabled={completedProposalExportFields(data)?.length === 0}
                    onClick={() => setProposalExport(data)}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>Materials list</ListItemText>
                  </MenuItem>

                  {data.status === 'sent' && <Divider />}

                  {data.status === 'sent' &&
                    statusElementsArray.map(({ verb, status, icon, color }) => (
                      <MenuItem
                        sx={{ color: `${color}.main` }}
                        onClick={() =>
                          setProposalStatus({
                            ...data,
                            newStatus: status,
                          })
                        }
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText>{verb}</ListItemText>
                      </MenuItem>
                    ))}

                  {/* {data.status === 'draft' && (
                        <MenuItem
                          onClick={() => handleDelete(data)}
                          sx={{ color: 'error.main' }}
                        >
                          <ListItemIcon sx={{ color: 'inherit' }}>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      )} */}
                </MenuCell>
              ),
            },
          ]}
        />
      </Card>
    </>
  )
}
