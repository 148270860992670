import { GlobalStyles } from '@mui/material'

export const sxAppStylesFlex = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  flexGrow: 1,
}

export const sxAppStylesWindow = {
  flexGrow: 1,
  mb: { sm: 2, md: 3, lg: 4 },
}

export default function AppStyles({ next, vite, style, ...props }) {
  return (
    <GlobalStyles
      styles={{
        html: { minHeight: '100%' },
        body: {
          minHeight: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...sxAppStylesFlex,
          ...style,
        },
        '#root, #__next': {
          height: '100%',
          '& main': {
            height: '100%',
            boxSizing: 'border-box',
            ...sxAppStylesFlex,
            ...style,
            // TODO: remove from rest of app and replace with a 'page' component
            // paddingLeft: 0,
            // paddingRight: 0,
          },
        },
      }}
    />
  )
}
