import { Box, Card, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import cardSx from '../../../../core/sx/cardSx'
import flexStackSx from '../../../../core/sx/flexStackSx'
import { formatDate } from '../../../../core/utils/formatDate'
import addressToArray from '../../../ViewProposal/addressToArray'
import StatusHeader from './StatusHeader'
import useDistributorEstimate from './useDistributorEstimate'

const RenderAddress = ({ address }) =>
  addressToArray(address).map(s => <Typography>{s}</Typography>)

function statusToText(status) {
  if (status.startsWith('cancelled')) return 'cancelled'
  if (status === 'sent') return 'completed'
  return status
}

export default function ProposalDetails() {
  const { distributorEstimateId } = useParams()
  const { data, mutate, upload, completeEstimate, cancelEstimate, ...rest } =
    useDistributorEstimate({ distributorEstimateId })

  if (!data) return null

  const status = data?.status
  const updatedAt = data?.createdAt
  const contractingFirm = data?.contractingFirm
  const contractor = data?.proposal?.contents?.contractor
  const customer = data?.proposal?.contents?.customer
  const systemNotes = data?.proposal?.contents?.details?.systemNotes || ''

  return (
    <>
      <Card sx={{ ...cardSx, ...flexStackSx }}>
        <StatusHeader status={status} />
        <Box
          sx={{
            ...flexStackSx,
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 4,
            '& h6': { mb: 1 },
          }}
        >
          <div>
            <Typography variant="h6">Quote {statusToText(status)}</Typography>
            <Typography>{formatDate(updatedAt)}</Typography>
            <Typography>{contractingFirm.companyName}</Typography>
          </div>
          <div>
            <Typography variant="h6">Contractor details</Typography>
            <Typography>
              {contractor.firstName} {contractor.lastName}
            </Typography>
            <Typography>{contractor.email}</Typography>
            <Typography>{contractor.phoneNumber}</Typography>
          </div>
          <div>
            <Typography variant="h6">Contractor address</Typography>
            <RenderAddress address={contractingFirm.address} />
          </div>
        </Box>
      </Card>

      {status === 'requested' && (
        <Card sx={{ ...cardSx, ...flexStackSx }}>
          <Typography variant="h5">Quote summary</Typography>
          <Box sx={{ ...flexStackSx, flexDirection: 'row' }}>
            <div>
              <Typography variant="h6">Homeowner details</Typography>
              <Typography>{customer.name}</Typography>
              <Typography>{customer.email}</Typography>
              <Typography>{customer.phone}</Typography>
            </div>
            <div>
              <Typography variant="h6">Address</Typography>
              <RenderAddress address={customer.address} />
            </div>
            <div>
              <Typography variant="h6">System notes</Typography>
              <Typography>{systemNotes}</Typography>
            </div>
          </Box>
        </Card>
      )}
    </>
  )
}
