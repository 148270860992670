import { Box, Button, Card, Typography } from '@mui/material'
import client from '~/client'
import CompaniesTable from '~/components/Companies/CompaniesTable'
import PageHeader from '~/components/Layouts/PageHeader'
import UsersTable from '~/components/Users/UsersTable'
import EditUser from '../../../../components/Users/EditUser'
import Drawer from '../../../../components/Layouts/Drawer'
import useSearchParams from '../../../../core/router/useSearchParams'
import { useNavigate } from 'react-router-dom'
import DeleteUser from '../../../../components/Users/DeleteUser'
import flexSplitSx from '../../../../core/sx/flexSplitSx'
import { PersonAdd } from '@mui/icons-material'
import AddNewUser from '../../../../components/Users/AddNewUser'
import useSubmatch from '../../../../core/router/useSubmatch'

export default function DistributorUsers() {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <>
      <PageHeader />
      <Box sx={{ ...flexSplitSx, justifyContent: 'flex-end', pb: 2 }}>
        {client.hasRoles([
          'rep_admin',
          'rep_sales_manager',
          'distributor_company_user',
        ]) && (
          <Button
            onClick={() =>
              navigate(`?addUser`, { state: { user: { companyId: '' } } })
            }
            endIcon={<PersonAdd />}
          >
            Add user
          </Button>
        )}
      </Box>

      <Card>
        <UsersTable
          disableEdit={client.hasOnlyRoles(
            'distributor_company_user',
            'distributor_company_admin'
          )}
          query="distributorCompanyRelations/users"
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: user })
          }
          onDeleteClick={user =>
            navigate(`?deleteUser=${user.userId}`, { state: user })
          }
        />

        <Drawer open={params.hasOwnProperty('addUser')}>
          <AddNewUser companyType="distributor" />
        </Drawer>

        <Drawer open={params.editUser}>
          <EditUser />
        </Drawer>

        <Drawer open={useSubmatch('delete')}>
          <DeleteUser />
        </Drawer>
      </Card>
    </>
  )
}
