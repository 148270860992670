export default function fetchProgress(url, options = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    // Extract options
    const { method = 'GET', headers = {}, body, onProgress } = options

    xhr.open(method, url, true)

    // Set headers
    for (const header in headers) xhr.setRequestHeader(header, headers[header])

    // Track upload progress
    xhr.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100
        if (typeof onProgress === 'function') onProgress?.(percentComplete)
      }
    })

    xhr.onload = () => {
      if (xhr.status === 200) resolve({ url: xhr.responseURL })
      else reject(new Error(`XHR Request Failed with status ${xhr.status}`))
    }

    xhr.onerror = () => reject(new Error('XHR Request Failed'))

    xhr.send(body)
  })
}
