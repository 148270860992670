import { IconButton, InputAdornment, Paper } from '@mui/material'

import { Close } from '@mui/icons-material'
import { TextFieldElement, useFormContext } from 'react-hook-form-mui'
import AutoTableSearchPopper from './AutoTableSearchPopper'

const AutoSearchTextField = props => {
  const { name, entityType } = props
  const { watch, setValue, setFocus } = useFormContext()
  const value = watch(name)

  return (
    <AutoTableSearchPopper name={name} {...props.slotProps?.iconButton}>
      <Paper>
        <TextFieldElement
          {...props}
          InputProps={{
            autoFocus: true,
            endAdornment: (
              <InputAdornment
                sx={{ visibility: Boolean(value) ? 'visible' : 'hidden' }}
              >
                <IconButton
                  onClick={() => {
                    setValue(name, '')
                    setFocus(name)
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Paper>
    </AutoTableSearchPopper>
  )
}

export default AutoSearchTextField
