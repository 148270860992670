import client from '~/client'

export const shouldOnboardCompany = user =>
  user?.role &&
  [
    'contract_firm_admin',
    'rep_admin',
    'distributor_admin',
    'distributor_company_admin',
    'super_admin',
  ].includes(user.role) &&
  user.company?.onboarded === false

export const shouldOnboardUser = user => user?.onboarded === false

export default function shouldOnboard(user) {
  return shouldOnboardCompany(user) || shouldOnboardUser(user)
}
