import client from '../../../client'

const alphaSort = (a, b) => a.label.localeCompare(b.label)

export const userRoleOptions = client.roles?.map(id => ({
  id,
  label: id.charAt(0).toUpperCase() + id.replace(/_/g, ' ').slice(1),
}))

export const userStatusOptions = [
  { id: 'invited', label: 'Invited' },
  { id: 'confirmed', label: 'Confirmed' },
  { id: 'disabled', label: 'Disabled' },
].sort(alphaSort)

export const companyStatusOptions = [
  { id: 'active', label: 'Active' },
  { id: 'disabled', label: 'Disabled' },
].sort(alphaSort)

export const proposalStatusOptions = [
  { id: 'contractor_accepted', label: 'Contractor accepted' },
  { id: 'homeowner_accepted', label: 'Homeowner accepted' },
  { id: 'sent', label: 'Sent' },
  { id: 'draft', label: 'Draft' },
  { id: 'contractor_rejected', label: 'Contractor rejected' },
  { id: 'homeowner_rejected', label: 'Homeowner rejected' },
].sort(alphaSort)

export const estimateStatusOptions = [
  { id: 'requested', label: 'Requested' },
  { id: 'completed', label: 'Completed' },
  { id: 'cancelledByHomeowner', label: 'Cancelled by homeowner' },
  { id: 'cancelledByContractor', label: 'Cancelled by contractor' },
].sort(alphaSort)
