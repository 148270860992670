import React from 'react'
import useCompanies from '~/components/Companies/useCompanies'
import client from '../../client'

// const mapDistributorNetworkResponse = data =>
//   data?.map(
//     ({
//       distributor: {
//         companyName,
//         companyId,
//         status,
//         address,
//         createdAt,
//         companyType,
//       },
//       distributorCompany: { companyName, companyId, status, companyType },
//       repCompany: { companyName, companyId, status, companyType },
//       createdBy: { firstName, lastName, companyId, companyType },
//       createdAt,
//       relationId,
//       relationType,
//     }) => ({})
//   )

// const mapContractorNetworkResponse = data =>
//   data?.map(
//     ({
//       contractingFirm: {
//         companyName,
//         companyId,
//         status,
//         address,
//         createdAt,
//         companyType,
//       }, // {}
//       repCompany: { companyName, companyId, companyType, status }, // {}
//       createdBy: { firstName, lastName, companyId, companyType }, // user
//       createdAt,
//       relationId,
//       relationType,
//     }) => ({})
//   )

export const useDistributorNetworkCompanies = ({ search } = {}) => {
  const { data: relations, ...rest } = useCompanies({
    query: 'distributor-network',
    search,
  })
  console.log('useDistributorNetworkCompanies RESPONSE', relations)

  const data = relations?.map(
    ({ distributor, distributorCompany, repCompany, createdBy, ...rest }) => ({
      ...rest,
      ...distributor,
      distributorCompanyName: distributorCompany?.companyName,
    })
  )

  console.log('useDistributorNetworkCompanies', data)

  return { data, ...rest }
}

export const useContractorNetworkCompanies = ({ search } = {}) => {
  const {
    data: relations,
    mutate,
    ...rest
  } = useCompanies({
    query: 'contractor-network',
    search,
  })

  const data = relations?.map(
    ({ contractingFirm, repCompany, createdBy, ...rest }) => ({
      ...rest,
      ...contractingFirm,
    })
  )

  const [isUpdatingDiscount, setIsUpdatingDiscount] = React.useState()
  const updateRelationDiscount = async ({ relationId }) => {
    try {
      const input = prompt('Enter discount %')
      const discount = parseFloat(input)

      if (input === null) return

      if (isNaN(discount) || discount > 100 || discount < 0) {
        enqueueSnackbar('Invalid discount', { variant: 'error' })
        return
      }
      setIsUpdatingDiscount(relationId)
      const res = await client.put('companyRelations', {
        companyRelationId: relationId,
        discount,
      })
      mutate()

      console.log('companyRelations res', res)
      enqueueSnackbar('Discount updated', { variant: 'success' })
    } finally {
      setIsUpdatingDiscount()
    }
  }

  console.log('useContractorNetworkCompanies', data)

  return { data, mutate, isUpdatingDiscount, updateRelationDiscount, ...rest }
}
