import { Button, MenuItem, Select, Stack, Typography } from '@mui/material'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import sharedProps from '../sharedProps'

export default function Pagination({
  page,
  totalPages,
  setPage,
  isNextPage,
  pageSize,
  setPageSize,
  sx,
}) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'right',
        alignItems: 'center',
        pt: 2,
        ...sx,
      }}
    >
      {typeof setPageSize === 'function' && (
        <Select
          size="small"
          value={pageSize}
          onChange={e => setPageSize(e.target.value)}
          // sx={{ py: 0 }}
          inputProps={{ sx: { py: 0.5 } }}
        >
          {[5, 10, 15, 25].map(n => (
            <MenuItem key={n} value={n}>
              {n}
            </MenuItem>
          ))}
        </Select>
      )}
      <Typography variant="body2" sx={{ ml: 1, mr: 'auto', color: 'grey.500' }}>
        Page {page + 1}
        {typeof totalPages === 'number' && ` of ${totalPages}`}
      </Typography>
      <Button
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
        startIcon={<ArrowBack />}
        {...sharedProps.button.table}
      >
        Back
      </Button>
      <Button
        disabled={!isNextPage}
        onClick={() => setPage(page + 1)}
        endIcon={<ArrowForward />}
        {...sharedProps.button.table}
      >
        Next
      </Button>
    </Stack>
  )
}
