import { Box, Card, Typography } from '@mui/material'
import useSanityPageQuery from '~/client/useSanityPageQuery'
import AutoTable from '~/components/Data/AutoTable/AutoTable'
import sharedProps from '~/components/sharedProps'
import useSearchParams from '~/core/router/useSearchParams'
import { categoryNameToTitle } from '~/core/sanity/findOptions'
import buildSanityProductQuery from '~/routes/Admin/ProductCatalogue/buildSanityProductQuery'

const fieldTemplateCategories = {
  airHandler: [
    { name: 'coil', fields: ['tonnage', 'orientation', 'direction'] },
    { name: 'coilAccessory', fields: ['tonnage', 'orientation', 'direction'] },
    { name: 'drainPan', fields: ['tonnage'] },
    {
      name: 'outlet',
      fields: [
        'tonnage',
        { name: 'outletCategory', value: 'supplyPlenumAccessory' },
      ],
    },
    {
      name: 'returnAirPart',
      fields: ['tonnage', { name: 'returnAirPartType', value: 'duct' }],
    },
  ],
  coil: [
    {
      name: 'airHandler',
      fields: ['tonnage', 'orientation', 'direction'],
    },
  ],
  coilAccessory: [
    {
      name: 'airHandler',
      fields: ['tonnage', 'orientation', 'direction'],
    },
  ],
}

const buildRelatedQuery = ({ category, product }) => {
  const fieldTemplate = fieldTemplateCategories[product?.spec?.category]?.find(
    template => template.name === category
  )?.fields

  if (!fieldTemplate) return null

  const spec = { category }

  for (const field of fieldTemplate)
    if (typeof field !== 'string') spec[field.name] = [field.value]
    else if (product.spec[field]) spec[field] = product.spec[field]

  const query = buildSanityProductQuery({ params: { spec } })

  return query
}

export default function RelatedProducts({ product }) {
  const fieldTemplates = fieldTemplateCategories[product?.spec?.category]

  if (!fieldTemplates) return null

  return (
    <Card {...sharedProps.card}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Compatible Unico equipment for {product.modelNumber}
      </Typography>
      {fieldTemplates.map(({ name }) => (
        <RelatedProductsByCategory
          key={name}
          category={name}
          product={product}
        />
      ))}
    </Card>
  )
}

export function RelatedProductsByCategory({ category, product }) {
  const query = buildRelatedQuery({ category, product })
  const response = useSanityPageQuery(query)

  const [searchParams, setSearchParams] = useSearchParams()
  const navigateProduct = product =>
    setSearchParams(({ modelNumber, ...p }) =>
      product?.modelNumber ? { ...p, modelNumber: product.modelNumber } : p
    )

  if (!response?.data?.length) return null

  return (
    <Box>
      <Typography variant="h4" gutterBottom color="grey.600">
        {categoryNameToTitle(category)}
      </Typography>
      <AutoTable
        appStyles
        {...response}
        columns={['modelNumber', 'title']}
        onRowClick={navigateProduct}
      />
    </Box>
  )
}

// const categories = findOptionsMap(['products', 'spec', 'category'])

// const allowedCategories = categories.filter(({ value }) =>
//   ['airHandler', 'coil', 'coilAccessory', 'drainPan', 'returnAirPart'].includes(
//     value
//   )
// )
