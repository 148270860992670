import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { NavLink, useLocation } from 'react-router-dom'
import routes from '~/routes'

import {
  AddToHomeScreen,
  ContentCopy,
  Feedback,
  FiberPin,
  HelpOutline,
  Logout,
} from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'
import React from 'react'
import SentryFeedbackDialog from '~/config/SentryFeedbackDialog'
import usePrevious from '~/core/utils/usePrevious'
import useCompanyDetails from '../../routes/Admin/Company/Profile/useCompanyDetails'
import { enqueueSnackbar } from 'notistack'

const NavListItem = ({
  handle: {
    title,
    Icon = () => null,
    target,
    menuAdornment,
    nav,
    ...handle
  } = {},
  path = '',
  superPath = '',
  active,
  sx,
  onClick,
  // children,
  ...rest
}) => {
  const allowed = typeof nav === 'function' ? nav() : nav === true

  if (!allowed) return null

  return (
    <ListItem disablePadding {...{ sx, onClick }}>
      <NavLink
        to={superPath + path}
        target={target}
        // TODO total hack right here
        {...(active &&
          `${superPath}${path}` !== '/admin/' && { className: 'active' })}
      >
        <ListItemButton
          sx={theme => ({
            width: '100%',
            pr: 2,
            'a.active &': {
              borderLeft: `${theme.spacing(1)} solid ${
                theme.palette.primary.main
              }`,
              pl: 1,
            },
          })}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Icon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: '#fff' }} primary={title} />
          {menuAdornment?.()}
        </ListItemButton>
      </NavLink>
    </ListItem>
  )
}

const NavListHeader = ({
  handle: { title, Icon = () => null, menuAdornment, ...handle },
  path,
  open,
  active,
  sx,
  onClick,
  // ...rest
}) => (
  <ListItem disablePadding {...{ sx, onClick }}>
    <ListItemButton sx={theme => ({ pr: 2 })}>
      <ListItemIcon sx={{ minWidth: 40 }}>
        <Icon sx={{ color: '#fff' }} />
      </ListItemIcon>
      <ListItemText sx={{ color: '#fff' }} primary={title} />
      {menuAdornment?.()}
      {!active && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
  </ListItem>
)

const NavListContainer = ({ superPath, children, handle, ...rest }) => {
  const location = useLocation()
  const active = location.pathname.startsWith(`${superPath}${rest.path || ''}`)
  // if (location.pathname === '/admin/fast-quote/details')
  //   console.log(
  //     '%cACTIVE',
  //     'color:red',
  //     location.pathname,
  //     `${superPath}${rest.path || ''}`
  //   )

  const [open, setOpen] = React.useState(active)
  // close when clicking out
  const previousActive = usePrevious(active)
  React.useEffect(() => {
    if (!active && previousActive) setOpen(false)
  }, [active, previousActive])

  const allowed =
    typeof handle?.nav === 'function' ? handle.nav() : handle?.nav !== false

  if (!allowed) return null

  const viableChildren = children?.filter(item => !item.index)
  const indexChild = children?.filter(item => item.index === true)

  return (
    <>
      {indexChild?.length || rest.element || rest.lazy || rest.Component ? (
        <NavListItem
          superPath={superPath}
          {...rest}
          handle={handle}
          active={active}
        />
      ) : (
        <NavListHeader
          {...rest}
          handle={handle}
          open={open}
          active={active}
          onClick={() => setOpen(o => !o)}
        />
      )}
      {viableChildren && (
        <Collapse in={active || open}>
          <List sx={{ p: 0 }}>
            {viableChildren?.map(({ children, handle, ...otherest }) => (
              <NavListItem
                sx={{ background: '#11172d' }}
                key={otherest.path}
                superPath={superPath + rest.path}
                handle={{ ...handle, Icon: undefined }}
                {...otherest}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default function NavbarMenu() {
  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'stretch',
        '& a': { width: '100%' },
      }}
    >
      {routes[0].children
        ?.find(r => r.path?.startsWith('admin'))
        ?.children?.map(item => (
          <NavListContainer
            superPath="/admin/"
            {...item}
            key={item.path || 'empty'}
          />
        ))}
      <OtherItems />
    </List>
  )
}

const OtherItems = () => {
  // const feedback = useSentryFeedback()
  const [open, setOpen] = React.useState(false)
  const { data } = useCompanyDetails()

  return (
    <>
      {/* <NavListContainer
      handle={{ nav: true, title: 'Installation', Icon: LinkIcon }}
      children={[
        {
          path: '//on-stack-app-staging.herokuapp.com/app/sales-cycle/336',
          handle: { nav: true, title: 'Product & installation' },
          disabled: true,
        },
      ]}
    /> */}
      <Box sx={{ flexGrow: 1 }} />
      <NavListItem
        path="admin/sign-out"
        handle={{
          nav: true,
          title: 'Log out',
          Icon: Logout,
        }}
      />
      <SentryFeedbackDialog open={open} onClose={() => setOpen(false)} />
      <ListItem onClick={() => setOpen(true)}>
        <ListItemButton sx={{ p: 0 }}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Feedback sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: '#fff' }} primary="Feedback" />
        </ListItemButton>
      </ListItem>
      <NavListItem
        path="https://unicosalesapp.zendesk.com/hc/en-us"
        handle={{
          nav: true,
          title: 'Help',
          Icon: HelpOutline,
          target: '_blank',
        }}
      />
      <NavListItem
        path="https://unicosalesapp.com/add-app/"
        handle={{
          nav: true,
          title: 'Add app',
          Icon: AddToHomeScreen,
          target: '_blank',
        }}
      />
      <ListItem
        title="PIN code"
        sx={{
          transition: 'opacity .3s',
          cursor: 'copy',
          opacity: Boolean(data?.relationshipPinCode) ? 1 : 0,
        }}
        onClick={() =>
          navigator.clipboard
            .writeText(data?.relationshipPinCode)
            .then(res => enqueueSnackbar('PIN copied to clipboard'))
            .catch(
              res =>
                console.error(res) ||
                enqueueSnackbar('Failed to copy', { variant: 'error' })
            )
        }
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <ContentCopy sx={{ color: '#fff' }} />
        </ListItemIcon>
        <Typography>Pairing code: {data?.relationshipPinCode}</Typography>
      </ListItem>
    </>
  )
}
