import { LinearProgress, Typography, styled } from '@mui/material'
import sharedProps from '../sharedProps'
import { alpha } from '@mui/material'

const margin = 0

export const LoadingBackdrop = styled('div')(({ theme, active, error }) => ({
  position: 'absolute',
  top: margin,
  left: margin,
  right: margin,
  bottom: margin,
  // borderRadius: 8,
  zIndex: 10,
  pointerEvents: 'none',
  background: 'rgba(255,255,255,0)',
  // backdropFilter: 'grayscale(0%) blur(0px)',
  backdropFilter: 'grayscale(0%)',
  transition: 'all .4s',
  border: '0px solid transparent',
  ...(active && {
    background: 'rgba(255,255,255,0.7)',
    // backdropFilter: 'grayscale(100%) blur(1px)',
    backdropFilter: 'grayscale(100%)',
    pointerEvents: 'all',
  }),
  ...(error && {
    border: `2px solid ${alpha(theme.palette.error.main, 0.5)}`,
    backdropFilter: 'grayscale(100%) blur(0px)',
    // background: 'rgba(255,240,240,0.7)',
    pointerEvents: 'all',
  }),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const LinearProgressTop = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
})
const LinearProgressBottom = styled(LinearProgress)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
})

const LoadingIndicators = ({
  isLoading,
  isValidating,
  isLagging,
  isSubmitting,
  error,
  data,
  ...rest
}) => (
  <LoadingBackdrop
    active={
      !isLagging &&
      (isSubmitting || error || (isLoading && typeof data === 'undefined'))
    }
    error={error}
    {...rest}
  >
    {(isValidating || isLoading || isLagging) && <LinearProgressTop />}
    {isSubmitting && (
      <LinearProgressBottom
        variant={
          typeof isSubmitting === 'number' ? 'determinate' : 'indeterminate'
        }
        value={isSubmitting}
      />
    )}
    {error && (
      <Typography color="error" variant="h4">
        {typeof error === 'string' ? error : "We couldn't load this data."}
      </Typography>
    )}
  </LoadingBackdrop>
)

export default LoadingIndicators
