import {
  Card,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import * as React from 'react'
import Chip from '~/components/Data/Chip'
import PageHeader from '~/components/Layouts/PageHeader'

import { DeleteOutline } from '@mui/icons-material'
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread'
import DottedMenu from '~/components/DottedMenu'
import { formatDate } from '~/core/utils/formatDate'
import useNotifications from '~/routes/Admin/Notifications/useNotifications'
import AutoSearchDateRange from '../../../components/Data/AutoTable/AutoSearchDateRange'
import AutoTable, {
  DateTimeCell,
} from '../../../components/Data/AutoTable/AutoTable'

const unwrap =
  fn =>
  ({ data, meta }) => ({ data: fn(data), meta })

export default function Notifications() {
  const [search, setSearch] = React.useState()

  const { data, updateRead, deleteNotification, pagination, ...rest } =
    useNotifications({ search })

  const unread = data?.filter(row => !row.dateRead) || []

  const [message, setMessage] = React.useState({})

  const handleClick = row => {
    setMessage(row)

    if (row.dateRead) return
    const dateRead = new Date().toISOString()
    updateRead({ ...row, dateRead })
  }

  return (
    <>
      <PageHeader
        title={
          <>
            Notifications
            <br />
            <Chip
              // label={getPlural('unread notification', unread.length)}
              // sx={{ ml: 2 }}
              sx={{ mt: 1, mb: -2 }}
              label={`${unread.length} unread`}
              count={unread.length}
              color={unread.length === 0 ? 'success' : 'warning'}
            />
          </>
        }
      />

      <Grid container spacing={3} sx={{ justifyTracks: 'flex-start' }}>
        <Grid item xs={12} lg={6}>
          {/* <Card sx={{ p: 2, mb: 3 }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Summary
            </Typography>
            <Typography variant="h4">Status</Typography>
            <Chip
              label={getPlural('unread notification', unread.length)}
              count={unread.length}
              color={unread.length === 0 ? 'success' : 'warning'}
            />
          </Card> */}
          <Card>
            {/* <Box sx={flexSplitSx}> */}
            {/* <Typography sx={{ ...flexSplitSx, p: 2 }} variant="h3">
              Notifications
              <Chip
                // label={getPlural('unread notification', unread.length)}
                label={`${unread.length} unread`}
                count={unread.length}
                color={unread.length === 0 ? 'success' : 'warning'}
              />
            </Typography> */}

            {/* </Box> */}

            <AutoTable
              columns={[
                'subject',
                {
                  field: 'dateSent',
                  Component: DateTimeCell,
                  headerSearch: <AutoSearchDateRange />,
                  sx: { width: 80 },
                },
                {
                  // field: 'dateRead',
                  // headerName: 'Status',
                  Component: ({ data }) => (
                    <TableCell align="right">
                      <Chip
                        size="small"
                        label={data.dateRead === null ? 'unread' : 'read'}
                        sx={{
                          textTransform: 'capitalize',
                          bgcolor:
                            data.dateRead === null
                              ? 'warning.main'
                              : 'success.main',
                        }}
                      />
                    </TableCell>
                  ),
                  sx: { width: 80 },
                },
                {
                  Component: ({ data: row }) => (
                    <TableCell sx={{ py: 1 }}>
                      <DottedMenu>
                        <MenuItem
                          onClick={e => {
                            updateRead({ ...row, dateRead: null })
                          }}
                        >
                          <ListItemIcon>
                            <MarkAsUnreadIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Mark as unread</ListItemText>
                        </MenuItem>
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          onClick={() => deleteNotification(row)}
                        >
                          <ListItemIcon sx={{ color: 'error.main' }}>
                            <DeleteOutline fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </DottedMenu>
                    </TableCell>
                  ),
                  sx: { width: 56 },
                },
              ]}
              data={data}
              onRowClick={handleClick}
              pagination={pagination}
              {...rest}
              setSearch={setSearch}
            />
          </Card>
        </Grid>
        {message?.body && (
          <Grid item xs={12} lg={6}>
            <Card sx={{ p: 2, mb: 3 }}>
              <Typography variant="h3" sx={{ mb: 1 }}>
                {message.subject}
              </Typography>
              <Typography variant="h4" sx={{ mb: 3 }}>
                {formatDate(message.dateSent)}
              </Typography>
              <Typography>{message.body}</Typography>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}
