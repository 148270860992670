import { Close, Send } from '@mui/icons-material'
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import client from '~/client'
import SubmitButton from '~/components/Data/SubmitButton'
import { enqueueSnackbar } from 'notistack'
import isDev from '~/config/isDev'
import ConsistentDialog from '../core/ConsistentDialog'

export default function SentryFeedbackDialog({ open, onClose, ...rest }) {
  const userInfo = client.getUserInfo()

  const defaultValues = {
    name: [userInfo?.firstName, userInfo?.lastName].filter(v => !!v).join(' '),
    email: userInfo?.email,
    message: '',
  }

  const formContext = useForm({ defaultValues })

  const handleSubmit = data =>
    Sentry.sendFeedback(data, { includeReplay: true })
      .then(res => {
        console.log('Feedback res', res)
        if (res?.statusCode !== 200) throw new Error('Failed to send feedback')

        enqueueSnackbar('Thank you for your valuable feedback', {
          variant: 'success',
        })
        onClose()
        formContext.reset()
      })
      .catch(e => {
        console.error({ ...e })
        enqueueSnackbar(
          "We couldn't send your feedback - probably because tracking is disabled",
          {
            variant: 'error',
          }
        )
      })

  return (
    <ConsistentDialog
      // PaperProps={{ sx: { p: 2, width: '100%', maxWidth: 400 } }}
      title="Send feedback"
      open={open}
      onClose={onClose}
      {...rest}
      fullWidth
    >
      <FormContainer onSuccess={handleSubmit} formContext={formContext}>
        <DialogContent>
          <Stack spacing={3}>
            <Typography component="ul" sx={{ pl: 2 }}>
              <li>What were you trying to do?</li>
              <li>What happened?</li>
              <li>What did you expect to happen?</li>
            </Typography>
            {!defaultValues.name && (
              <TextFieldElement name="name" label="Name" />
            )}
            {!defaultValues.email && (
              <TextFieldElement name="email" label="Email" />
            )}
            <TextFieldElement
              multiline
              minRows={6}
              name="message"
              label="Message"
              required
            />
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: 'space-between' }}
            >
              <SubmitButton endIcon={<Send />} color="success">
                Send feedback
              </SubmitButton>
              <Button onClick={onClose} variant="text" endIcon={<Close />}>
                Close
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </FormContainer>
    </ConsistentDialog>
  )
}
