import { Box, Button, Card, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { PersonAdd } from '@mui/icons-material'
import client from '~/client'
import Drawer from '~/components/Layouts/Drawer'
import AddNewUser from '~/components/Users/AddNewUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import useSearchParams from '~/core/router/useSearchParams'
import cardSx from '~/core/sx/cardSx'
import flexSplitSx from '~/core/sx/flexSplitSx'
import GlobalPercentagePrice from '../../../Company/Pricing/GlobalPercentagePrice'
import PricingTable from '../../../Company/Pricing/PricingTable'
import CompanyProfile from '../../../Company/Profile'
import PageHeader from '../../../../../components/Layouts/PageHeader'
import DeleteUser from '../../../../../components/Users/DeleteUser'
import useSubmatch from '../../../../../core/router/useSubmatch'

export default function DistributorBranch() {
  const { branchId } = useParams()

  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()

  return (
    <>
      <PageHeader />
      <Card sx={cardSx}>
        <Box sx={flexSplitSx}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Users
          </Typography>
          {/* {isAdmin && ( */}
          <Button
            onClick={() =>
              navigate(`?addUser`, {
                state: {
                  company: {
                    companyId: branchId,
                    companyType: 'distributor',
                  },
                },
              })
            }
            endIcon={<PersonAdd />}
          >
            Add user
          </Button>
        </Box>

        {/* )} */}
        <UsersTable
          // disableEdit={client.hasOnlyRoles(['rep_admin', 'rep_sales_manager'])}
          disableEdit={client.hasOnlyRoles(
            'distributor_company_user', // TEMP PATCH
            'distributor_company_admin', // TEMP PATCH
            'rep_admin',
            'rep_sales_manager'
          )}
          query={`distributorCompanyRelations/users?distributorId=${branchId}`}
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: { user } })
          }
        />
        <Drawer open={params.hasOwnProperty('addUser')}>
          <AddNewUser
            companyType="distributor"
            values={{ companyId: branchId }}
          />
        </Drawer>
        <Drawer open={params.editUser}>
          <EditUser />
        </Drawer>
        <Drawer open={useSubmatch('delete')}>
          <DeleteUser />
        </Drawer>
      </Card>

      {client.hasRoles('distributor_') && (
        <>
          <CompanyProfile companyId={branchId} />
          <GlobalPercentagePrice companyId={branchId} />
          <PricingTable companyId={branchId} />
        </>
      )}
    </>
  )
}
