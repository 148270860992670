import { Box, Card, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { Delete, Forest, LinkOff, People } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import client from '~/client'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import AutoTable, {
  ChipCell,
  DateCell,
  DisableCell,
  TableCell,
} from '~/components/Data/AutoTable/AutoTable'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import useSearchParams from '~/core/router/useSearchParams'
import flexSplitSx from '~/core/sx/flexSplitSx'
import { useDistributorNetworkCompanies } from '../../../../components/Companies/useNetworkCompanies'
import { sxAppStylesFlex } from '../../../../components/Layouts/AppStyles'
import AutoSearchMultiCheck from '../../../../components/Data/AutoTable/AutoSearchMultiCheck'
import { companyStatusOptions } from '../../../../components/Data/AutoTable/statusOptions'
import CreatePairingModal from '../../Company/Relations/CreatePairingModal'
import SubmitButton from '../../../../components/Data/SubmitButton'

export default function DistributorBranch() {
  const [search, setSearch] = React.useState()
  const { data, deleteCompany, unpairRelation, isUnpairing, ...response } =
    useDistributorNetworkCompanies({
      search,
    })

  //GET {{host}}/repCompanyRelations/companies?pageSize=10&search={"contractingFirm": {"companyType": "distributor"} } HTTP/1.1

  const branches = data
  // ?.map(({ contractingFirm, ...relation }) => ({
  //   ...contractingFirm,
  //   // distributorCompanyName: relation.distributorCompany.companyName,
  // }))
  const navigate = useNavigate()

  const [params, setParams] = useSearchParams()

  const userInfo = client.getUserInfo()

  console.log(branches)

  return (
    <>
      <PageHeader />

      <Box sx={{ ...flexSplitSx, justifyContent: 'space-between', mb: 2 }}>
        {client.hasRoles('contract_firm_admin') && (
          <CreatePairingModal mode="distributor" />
        )}
        {client.hasRoles([
          'distributor_admin',
          'distributor_user',
          'unico_admin',
        ]) && (
          <Button
            onClick={() =>
              navigate(`?addCompany`, {
                state: {
                  companyType: 'distributor',
                  distributorCompanyId: userInfo.companyId,
                },
              })
            }
            endIcon={<Forest />}
            sx={{ mr: 0, ml: 'auto' }}
          >
            Add new branch
          </Button>
        )}
      </Box>

      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          {...response}
          setSearch={setSearch}
          data={branches}
          titleField="companyName"
          uniqueField="companyId"
          columns={[
            { field: 'createdAt', Component: DateCell, headerName: 'Created' },
            {
              field: 'distributorCompanyName',
              headerName: 'Parent name',
              Component: props => (
                <TableCell>{props.data.distributorCompanyName}</TableCell>
              ),
            },
            { field: 'companyName', headerName: 'Company name' },
            'relationshipPinCode',
            { field: 'companyType', Component: ChipCell },
            {
              field: 'status',
              Component: ChipCell,
              headerSearch: (
                <AutoSearchMultiCheck options={companyStatusOptions} />
              ),
            },
            // {
            //   field: 'edit',
            //   Component: EditCell,
            //   // to: 'editCompany='
            //   onClick: branch => navigate(`?editCompany=${branch.companyId}`),
            // },
            {
              Component: ({ data }) => (
                <TableCell align="right">
                  {client.hasRoles([
                    'distributor_company_admin',
                    'distributor_company_user',
                  ]) && (
                    <>
                      <Link to={`./${data.companyId}`} state={data}>
                        <Button
                          {...sharedProps.button.table}
                          startIcon={<People />}
                        >
                          Edit
                        </Button>
                      </Link>
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<Delete />}
                        onClick={deleteCompany}
                      ></Button>
                    </>
                  )}

                  {client.hasRoles(['rep_sales_manager', 'rep_admin']) && (
                    <Link to={`./${data.companyId}`} state={data}>
                      <Button
                        {...sharedProps.button.table}
                        startIcon={<People />}
                      >
                        Users
                      </Button>
                    </Link>
                  )}

                  {client.hasRoles(['contract_firm_admin']) && (
                    // <Link to={`./${data.companyId}`} state={data}>
                    <SubmitButton
                      isSubmitting={isUnpairing === data.relationId}
                      type="button"
                      {...sharedProps.button.table}
                      endIcon={<LinkOff />}
                      color="error"
                      onClick={() =>
                        confirm(
                          `Are you sure you want to unpair from ${
                            data.companyName || 'this company'
                          }? You will not be able to pair again.`
                        ) && unpairRelation(data)
                      }
                      // onClick={() => console.log('unpairRelation', data)}
                    >
                      Unpair
                    </SubmitButton>
                    // </Link>
                  )}
                </TableCell>
              ),
            },
            // ...(client.hasRoles([
            //   'distributor_company_admin',
            //   'distributor_company_user',
            // ])
            //   ? [
            //       {
            //         field: 'viewBranch',
            //         Component: ({ data, ...rest }) => (
            //           <TableCell sx={{ px: '0 !important' }}>
            //             <Link to={`./${data.companyId}`} state={data}>
            //               <Button
            //                 {...sharedProps.button.table}
            //                 startIcon={<People />}
            //               >
            //                 Edit
            //               </Button>
            //             </Link>
            //           </TableCell>
            //         ),
            //       },
            //       {
            //         field: 'disable',
            //         Component: DisableCell,
            //         width: 0.5,
            //         onClick: deleteCompany,
            //       },
            //     ]
            //   : client.hasRoles(['rep_sales_manager', 'rep_admin'])
            //   ? [
            //       {
            //         field: 'editUsers',
            //         Component: ({ data, ...rest }) => (
            //           <TableCell sx={{ px: '0 !important' }}>
            //             <Link to={`./${data.companyId}`} state={data}>
            //               <Button
            //                 {...sharedProps.button.table}
            //                 startIcon={<People />}
            //               >
            //                 Users
            //               </Button>
            //             </Link>
            //           </TableCell>
            //         ),
            //       },
            //     ]
            //   : []),
          ]}
        />
      </Card>
      <Drawer open={params.hasOwnProperty('addCompany')}>
        <AddCompanyForm
          values={{
            companyType: 'distributor',
            distributorCompanyId: userInfo.companyId,
          }}
        />
      </Drawer>
    </>
  )
}
