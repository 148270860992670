import { Button, Paper } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { CheckboxButtonGroup, useFormContext } from 'react-hook-form-mui'
import client from '../../../client'
import AutoTableSearchPopper from './AutoTableSearchPopper'

const AutoSearchMultiCheck = props => {
  const { name, entityType, options = [] } = props
  const { watch, setValue, setFocus } = useFormContext()
  const value = watch(name)

  return (
    <AutoTableSearchPopper name={name}>
      <Paper sx={{ p: 2, py: 1, flexDirection: 'row' }}>
        <Button
          variant="text"
          startIcon={<Clear />}
          onClick={() => {
            setValue(name, null)
            setFocus(name)
          }}
          disabled={!value?.length > 0}
          sx={{
            pl: 1,
            ml: -0.5,
            my: 0,
            width: '100%',
            justifyContent: 'start',
          }}
        >
          Clear&nbsp;selection
        </Button>
        <div />
        <CheckboxButtonGroup name={name} options={options} />
      </Paper>
    </AutoTableSearchPopper>
  )
}

export default AutoSearchMultiCheck
