import { Box, Button, Card, Grid, Typography } from '@mui/material'
import PageHeader from '~/components/Layouts/PageHeader'

import { PostAdd } from '@mui/icons-material'
import AutoTable, {
  ContentPreviewCell,
  DateCell,
  DeleteCell,
  EditCell,
} from '~/components/Data/AutoTable/AutoTable'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import Drawer from '~/components/Layouts/Drawer'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'

import EditTextTemplateForm from '~/routes/Admin/ProposalSetup/TextTemplates/EditTextTemplateForm'
import useTextTemplates from '~/routes/Admin/ProposalSetup/TextTemplates/useTextTemplates'
import { sxAppStylesFlex } from '../../../../components/Layouts/AppStyles'
import React from 'react'

export default function TextTemplates() {
  const [search, setSearch] = React.useState()

  const response = useTextTemplates({ search })
  const { isSubmitting, isLoading, saveTemplate, deleteTemplate } = response

  return (
    <>
      <PageHeader />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to="new">
          <Button sx={{ mb: 2 }} variant="outlined" endIcon={<PostAdd />}>
            Add new
          </Button>
        </Link>
      </Box>
      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          {...response}
          columns={[
            // { field: 'name', Component: ContentPreviewCell },
            { field: 'name' },
            {
              field: 'contents',
              headerName: 'Preview',
              Component: ContentPreviewCell,
            },
            { field: 'updatedAt', Component: DateCell },
            { field: 'edit', Component: EditCell },
            {
              field: 'delete',
              Component: DeleteCell,
              onClick: deleteTemplate,
            },
          ]}
        />
      </Card>

      {/* <LoadingIndicators {...{ isSubmitting, isLoading }} /> */}

      <Drawer open={useSubmatch('edit')}>
        <EditTextTemplateForm onSuccess={saveTemplate} />
      </Drawer>

      <Drawer open={useSubmatch('new')}>
        <EditTextTemplateForm onSuccess={saveTemplate} />
      </Drawer>
    </>
  )
}
