import { Card, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import useSWR from 'swr'
import client from '~/client'
import AddressFields from '~/routes/Admin/Company/Profile/AddressFields'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import sharedProps from '~/components/sharedProps'
import useUserProfile from '~/routes/Admin/Profile/useUserProfile'

export default function EditProfile({ fieldProps }) {
  const { data, error, isLoading, isSubmitting, updateUserProfile } =
    useUserProfile()

  // React.useEffect(() => void console.log('editprofile.jsx', data))

  const formContext = useForm({
    defaultValues: data,
    shouldUnregister: true,
  })

  React.useEffect(() => void formContext.reset(data), [data])

  return (
    <Card {...sharedProps.card}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Profile
      </Typography>
      <FormContainer formContext={formContext} onSuccess={updateUserProfile}>
        <Stack spacing={3} sx={{ alignItems: 'flex-start' }}>
          <TextFieldElement
            required
            fullWidth
            name="firstName"
            label="First name"
            type="text"
            {...fieldProps}
          />
          <TextFieldElement
            required
            fullWidth
            name="lastName"
            label="Last name"
            type="text"
            {...fieldProps}
          />

          <TextFieldElement
            required
            fullWidth
            name="phoneNumber"
            label="Phone number"
            type="tel"
            {...fieldProps}
          />

          <TextFieldElement
            required
            fullWidth
            name="jobTitle"
            label="Job title"
            {...fieldProps}
          />

          <Typography variant="h4">Address</Typography>
          <AddressFields fieldProps={fieldProps} />

          <SubmitButton isSubmitting={isSubmitting} {...fieldProps} />
        </Stack>
      </FormContainer>
      <LoadingIndicators {...{ isLoading, isSubmitting, error }} />
    </Card>
  )
}
