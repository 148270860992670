import { LockReset, Save } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router-dom'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import useCompanyOptions from '~/components/Users/useCompanyOptions'
import useMutateUsers from '~/components/Users/useMutateUsers'
import AutocompleteElementOptions from '~/core/forms/AutocompleteElementOptions'
import useSystemRoles from './useSystemRoles'

export default function EditUser({ allowedRoles }) {
  const navigate = useNavigate()

  const location = useLocation()
  const user = location.state?.data
  const userInfo = client.getUserInfo()

  console.log(user, userInfo)

  const isSelf = user?.email && user.email === userInfo.email

  const mutateUsers = useMutateUsers()

  const [isResetting, setIsResetting] = React.useState()
  const handleResetPasswordClick = async () => {
    if (!confirm(`Are you sure you want to reset password for ${user.email}?`))
      return
    try {
      setIsResetting(true)
      const res = await client.post('auth/forgotpw', { username: user.email })
      enqueueSnackbar(`Password reset email has been sent to ${user.email}`)
    } catch (e) {
    } finally {
      setIsResetting(false)
    }
  }

  const [isSaving, setIsSaving] = React.useState()
  const handleSaveClick = async data => {
    try {
      setIsSaving(true)
      const res = await client.patch('protected', {
        ...data,
        email: user.email,
        // username: user.email,
      })

      mutateUsers()

      if (isSelf) {
        enqueueSnackbar(`Your user info has been updated - signing out.`, {
          variant: 'info',
        })
        client.signOut()
        navigate(`/sign-in?username=${userInfo.email}&password=`)
      } else {
        enqueueSnackbar(`User ${user.email} updated`)
        // navigate('..', { relative: 'path' })
        navigate(-1)
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsSaving(false)
    }
  }

  const isSubmitting = isSaving || isResetting

  const formContext = useForm({
    defaultValues: { ...user },
    shouldUnregister: true,
  })

  const systemRoles = useSystemRoles(allowedRoles)

  console.log('EditUser', { location, user, userInfo, systemRoles })

  return (
    <FormContainer onSuccess={handleSaveClick} formContext={formContext}>
      <Stack spacing={3}>
        <Typography variant="h3">Edit user</Typography>
        <TextFieldElement
          fullWidth
          name="firstName"
          label="First name"
          required
        />
        <TextFieldElement
          fullWidth
          name="lastName"
          label="Last name"
          required
        />
        {/* <TextFieldElement
            fullWidth
            name="email"
            label="Email"
            type="email"
            required
          /> */}
        {/* <TextFieldElement
            fullWidth
            name="temp_password"
            label="Temporary password"
            autoComplete="off"
            type="password"
            required
            rules={{ pattern: cognitoPassword }}
          /> */}
        <SelectElement
          fullWidth
          name="role"
          label="Role"
          required
          options={systemRoles}
        />

        {client.hasRoles() && (
          <AutocompleteElementOptions
            fullWidth
            name="companyId"
            label="Company"
            required
            options={useCompanyOptions()}
            helperText="Developer use only"
          />
        )}

        {isSelf && (
          <Typography color="error.main">
            You are editing yourself - you'll be signed out after saving
            changes.
          </Typography>
        )}

        <SubmitButton
          sx={{ flexShrink: 0, alignSelf: 'start' }}
          isSubmitting={isSaving}
          // endIcon={<SendIcon />}
          endIcon={<Save />}
        />
        <Typography variant="h3">Reset password</Typography>
        <SubmitButton
          sx={{ flexShrink: 0, alignSelf: 'start' }}
          isSubmitting={isResetting}
          endIcon={<LockReset />}
          type="button"
          onClick={handleResetPasswordClick}
          // color="warning"
        >
          Send reset password email
        </SubmitButton>
      </Stack>
      <LoadingIndicators isSubmitting={isSubmitting} />
    </FormContainer>
  )
}
