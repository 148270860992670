import { ClickAwayListener, IconButton, Popper } from '@mui/material'

import { Search } from '@mui/icons-material'
import React from 'react'
import { useFormContext } from 'react-hook-form-mui'
import formatSearchObject from './formatSearchObject'

const AutoTableSearchPopper = ({ name, children, ...rest }) => {
  const { watch } = useFormContext()
  const value = watch(name)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = e => setAnchorEl(open ? null : e.currentTarget)
  const onClose = () => setAnchorEl(null)

  const active = formatSearchObject(value)

  React.useEffect(() => {
    const handleKeyDown = e => e.key === 'Escape' && onClose()
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <>
      <IconButton
        {...rest}
        sx={{
          opacity: active || open ? 1 : 0.3,
          width: 24,
          height: 24,
          color: active ? 'warning.main' : open ? 'primary.main' : 'inherit',
          ...rest.sx,
        }}
        onClick={handleClick}
        size="small"
      >
        <Search />
      </IconButton>
      <Popper
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: theme => theme.zIndex.appBar - 1 }}
        onKeyDown={e => e.key === 'Escape' && onClose()}
      >
        <ClickAwayListener onClickAway={onClose}>
          <div>{children}</div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default AutoTableSearchPopper
