import useSWR from 'swr'
import client from '../../../../client'
import { enqueueSnackbar } from 'notistack'
import fetchProgress from '../../Company/Assets/fetchProgress'
import React from 'react'

export const getFileExtension = filename =>
  filename?.match(/\.[0-9a-z]{1,5}$/i)?.[0]

export const getFileName = filename =>
  filename?.match(/([^\/\\]+)(?=\.[^\/\\]+$)|([^\/\\]+$)/)?.[0]

const usePresignedUpload = propsOptions => {
  const [progress, setProgress] = React.useState(null)

  const upload = async (
    file,
    {
      filename = propsOptions?.filename || getFileName(file.name),
      ...options
    } = {}
  ) => {
    try {
      setProgress(1)

      const params = new URLSearchParams({
        ...propsOptions,
        ...options,
        filename: filename + getFileExtension(file.name),
      })

      const path = `presigned?${params.toString()}`

      const presignedUrl = await client.get(path)

      const res = await fetchProgress(presignedUrl, {
        method: 'PUT',
        body: file,
        'Content-Type': file.type,
        onProgress: e => setProgress(e * 0.95),
      })

      return res
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      setProgress(false)
    }
  }

  return { upload, progress }
}

export default function useDistributorEstimate({ distributorEstimateId }) {
  const { data, mutate, ...rest } = useSWR(
    `distributorEstimates/${distributorEstimateId}`,
    client.fetcher
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const { upload, progress, ...plop } = usePresignedUpload({
    type: 'distributor-estimate',
    distributorEstimateId,
    filename: `estimate-${distributorEstimateId}`,
  })

  const completeEstimate = async ({ lineItems, distributorMessage }) => {
    setIsSubmitting(true)
    try {
      await client.put(`distributorEstimates/${distributorEstimateId}`, {
        lineItems,
        distributorMessage,
      })
      mutate()
      enqueueSnackbar('Estimate completed', { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Error completing estimate', { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const cancelEstimate = async () => {
    if (confirm('Are you sure you want to cancel this estimate?')) {
      setIsSubmitting(true)
      try {
        await client.delete(`distributorEstimates/${distributorEstimateId}`)
        mutate()
        enqueueSnackbar('Estimate cancelled')
      } catch (e) {
        console.log(e)
        enqueueSnackbar('Error canceling estimate', { variant: 'error' })
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const [isRemoving, setIsRemoving] = React.useState(false)
  const removeAttachment = async () => {
    setIsRemoving(true)
    try {
      await client.put(`distributorEstimates/${distributorEstimateId}`, {
        attachmentFileUrl: null,
      })
      mutate()
      enqueueSnackbar('Attachment removed')
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsRemoving(false)
    }
  }

  return {
    data,
    mutate,
    ...rest,
    completeEstimate,
    cancelEstimate,
    upload,
    isSubmitting,
    uploadAttachment: upload,
    progress,
    isRemoving,
    removeAttachment,
  }
}
