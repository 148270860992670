import { Alert, Typography } from '@mui/material'

export default function StatusHeader({ status }) {
  if (status === 'completed')
    return (
      <>
        <Typography variant="h5" color="success">
          Estimate request completed
        </Typography>
        <Alert color="info">
          This estimate has already been returned to the requestor.
        </Alert>
      </>
    )

  if (status === 'cancelledByContractor')
    return (
      <>
        <Typography variant="h5" color="success">
          Estimate request cancelled
        </Typography>
        <Alert color="info">
          This quote request was canceled by the contractor.
          <br />
          Please get in touch with the user for further information.
        </Alert>
      </>
    )

  return <Typography variant="h5">Estimate requested</Typography>
}
