import {
  AttachFile,
  Attachment,
  Delete,
  OpenInNew,
  Replay,
  Upload,
} from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import usePresignedUpload from '~/routes/Admin/Company/Profile/usePresignedUpload'

import { alpha, styled } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import { DropZoneBackdrop } from '~/core/uploads/StyledDropZone'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import SubmitButton from '~/components/Data/SubmitButton'
import useDistributorEstimate from './useDistributorEstimate'
import { useParams } from 'react-router-dom'
import flexStackSx from '../../../../core/sx/flexStackSx'
import client from '../../../../client'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const mimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

// /presigned?type=distributor-estimate&filename=test.pdf&distributorEstimateId=e4267b48-4d72-40d3-9279-90c64c8a1ad3

export default function EstimateFileUpload({
  value,
  label,
  accept = mimeTypes,
  ...rest
}) {
  const { distributorEstimateId } = useParams()
  const {
    data: estimate,
    isLoading,
    upload,
    isUploading,
    mutate,
    uploadAttachment,
    progress,
    isRemoving,
    removeAttachment,
  } = useDistributorEstimate({ distributorEstimateId })

  const attachmentFileUrl = estimate.attachmentFileUrl

  // const [isDeleting, setIsDeleting] = React.useState(false)

  // const onDelete = async () => {
  //   try {
  //     setIsDeleting(true)
  //     await updateCompany({ exclusionsTemplateFile: null })
  //   } finally {
  //     setIsDeleting(false)
  //   }
  // }

  const onDrop = async files => {
    const file = files[0]
    if (!file) return

    try {
      await uploadAttachment(file)
      await mutate()
      enqueueSnackbar('File attached', { variant: 'success' })
    } catch (e) {
      console.error(e)
      enqueueSnackbar(e, { variant: 'error' })
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: mimeTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    onDrop,
    ...rest,
  })

  const { onClick, ...rootProps } = getRootProps()

  return (
    <>
      <Box
        {...rootProps}
        sx={{ '&:hover > div:first-child': { pointerEvents: 'none' } }}
      >
        <input {...getInputProps()} />
        <DropZoneBackdrop
          {...{
            isDragActive,
            isDragAccept,
            isDragReject,
          }}
        />

        <Box sx={{ ...flexStackSx, flexDirection: 'row' }}>
          <SubmitButton
            type="button"
            variant="contained"
            endIcon={attachmentFileUrl ? <Replay /> : <Upload />}
            onClick={onClick}
            isSubmitting={progress}
          >
            {attachmentFileUrl ? 'Replace' : 'Upload'}
          </SubmitButton>

          {estimate.attachmentFileUrl && (
            <>
              <Button
                variant="text"
                endIcon={<OpenInNew />}
                sx={{ mr: 'auto', display: 'flex' }}
                as="a"
                target="_blank"
                href={`${CDN_DOMAIN}${estimate.attachmentFileUrl}`}
              >
                View
              </Button>

              <SubmitButton
                type="button"
                variant="text"
                color="error"
                endIcon={<Delete />}
                onClick={removeAttachment}
                isSubmitting={isRemoving}
              >
                Remove
              </SubmitButton>
            </>
          )}
        </Box>
        <LoadingIndicators isSubmitting={progress} isLoading={isLoading} />
      </Box>
    </>
  )
}
