import MenuIcon from '@mui/icons-material/Menu'
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import UnicoLogo from './UnicoLogo'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import useScript from '~/core/hooks/useScript'
import useMaintenance from '~/routes/Maintenance/useMaintenance'
import NavbarMenu from './NavbarMenu'
import ScrollToTop from './ScrollToTop'
import useResumeOnboarding from '~/routes/Onboarding/useResumeOnboarding'

// const useLayoutAnimationKey = () => {
//   const match = useMatches().slice(-1)[0]

//   const subpath = match?.params?.['*']
//   const superpath = match.pathname.replace(subpath, '')
//   return superpath
// }
// //

// const variants = {
//   initial: { x: '-100%' },
//   animate: { x: 0 },
//   exit: { x: '-100%' },
// }

// const navVariants = {
//   hidden: { x: '-100%' },
//   exit: { x: '-100%' },
//   mobileOpen: {
//     x: 0,
//     clipPath: `circle(300vh at 40px 40px)`,
//     transition: {
//       type: 'spring',
//       stiffness: 20,
//       restDelta: 2,
//       // staggerChildren: 0.5,
//       // when: 'beforeChildren',
//     },
//   },
//   mobileClosed: {
//     clipPath: 'circle(22px at 32px 32px)',
//     transition: {
//       type: 'spring',
//       stiffness: 400,
//       damping: 40,
//     },
//     // transition: { ease: [0.4, 0, 0.2, 1] },
//   },
//   desktop: {
//     x: 0,
//     clipPath: `circle(300vh at 40px 40px)`,

//     // clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
//     // clipPath: 'none',
//   },
// }

const sideBarWidth = 260

export default function RootLayout() {
  const location = useLocation()

  // const key = useLayoutAnimationKey()

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => void setOpen(false), [location])

  const handleClick = () => setOpen(o => !o)

  const hasSideBar = location.pathname.startsWith('/admin')

  useMaintenance()

  useScript({
    src: 'https://static.zdassets.com/ekr/snippet.js?key=ba8e5993-37bb-45de-b4de-13e49a2f036e',
    // enabled: isDev(),
  })

  useResumeOnboarding()

  return (
    <>
      <ScrollToTop />
      {hasSideBar && (
        <>
          <AppBar
            key="appBar"
            position="fixed"
            sx={{ display: { md: 'none' } }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              {/* <UnicoLogo
                width="30%"
                height="auto"
                sx={{ marginRight: 0, marginLeft: 'auto' }}
              /> */}
            </Toolbar>
          </AppBar>

          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            anchor="left"
            open={open}
            onClose={handleClick}
            // onOpen={handleClick}
            // onClick={handleClick}
            PaperProps={{
              sx: {
                background: '#16203d',
                color: '#fff',
                width: { xs: 280, md: sideBarWidth },
              },
            }}
          >
            <Box sx={{ p: 2, maxWidth: 180, mx: 'auto' }}>
              <UnicoLogo width="100%" height="auto" />
            </Box>
            <NavbarMenu />
          </Drawer>
        </>
      )}
      {/* <AnimatePresence mode="popLayout">
        <motion.div
          key={key}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        > */}
      <Box
        component="main"
        sx={{
          pb: 2,
          px: { xs: 1, sm: 2, md: 3, lg: 5 },
          pt: { xs: 8, sm: 10, md: 0 },
          ...(hasSideBar && { ml: { md: `${sideBarWidth}px` } }),
          containerType: 'size',
          containerName: 'main',
        }}
      >
        {/* <MotionOutlet /> */}
        <Outlet />
      </Box>
      {/* </motion.div>
      </AnimatePresence> */}
      {/* {isDev() && ( */}

      {/* )} */}
    </>
  )
}
