import { enqueueSnackbar } from 'notistack'
import React from 'react'
import useSWR from 'swr'
import client from '~/client'
import useLocalStorage from '~/core/utils/useLocalStorage'

export default function useUserProfile() {
  const [token] = useLocalStorage('auth')
  const isLoggedIn = typeof token?.userInfo?.email === 'string'

  const { data, mutate, ...rest } = useSWR(
    isLoggedIn ? 'protected/?current=1' : null,
    client.fetcher
  )

  const [isSubmitting, setSubmitting] = React.useState(false)

  const updateUserProfile = async data => {
    try {
      setSubmitting(true)
      await client.patch('protected', data)
      enqueueSnackbar('Your details have been saved')
      mutate()
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    data,
    isSubmitting,
    updateUserProfile,
    ...rest,
  }
}
