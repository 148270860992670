import PageHeader from '~/components/Layouts/PageHeader'
import AutoTable, {
  ButtonCell,
  ChipCell,
  DateCell,
  DateTimeCell,
} from '~/components/Data/AutoTable/AutoTable'
import useDistributorEstimates from './useDistributorEstimates'
import { Card, TableCell } from '@mui/material'
import cardSx from '~/core/sx/cardSx'
import { Edit } from '@mui/icons-material'
import React from 'react'
import { sxAppStylesFlex } from '../../../components/Layouts/AppStyles'
import AutoTableSearchColumns from '../../../components/Data/AutoTable/AutoTableSearchColumns'
import AutoSearchMultiCheck from '../../../components/Data/AutoTable/AutoSearchMultiCheck'
import { proposalStatusOptions } from '../../../components/Data/AutoTable/statusOptions'

export default function Estimates() {
  const [search, setSearch] = React.useState()

  const { data, ...rest } = useDistributorEstimates(undefined, {
    search,
    sorting: { field: 'updatedAt', order: 'desc' },
  })

  console.log('Estimates', data)
  return (
    <>
      <PageHeader />
      <Card sx={sxAppStylesFlex}>
        <AutoTable
          appStyles
          setSearch={setSearch}
          data={data}
          {...rest}
          columns={[
            // 'contractingFirm',
            { field: 'updatedAt', Component: DateTimeCell },
            {
              field: 'status',
              Component: ChipCell,
              headerSearch: (
                <AutoSearchMultiCheck options={proposalStatusOptions} />
              ),
            },
            {
              field: 'contractingFirm',
              Component: props => (
                <TableCell>{props.data.contractingFirm?.companyName}</TableCell>
              ),
            },
            // {
            //   field: 'Homeowner name',
            //   Component: props => (
            //     <TableCell>{props.data.contractingFirm?.companyName}</TableCell>
            //   ),
            // },
            {
              field: 'requestedBy',
              Component: props => (
                <TableCell>
                  {props.data.requestedByUser?.firstName}{' '}
                  {props.data.requestedByUser?.lastName}
                </TableCell>
              ),
            },
            {
              field: 'edit',
              Component: props => (
                <ButtonCell
                  to={`/admin/estimates/${props.data.distributorEstimateId}`}
                  label="Edit"
                  startIcon={<Edit />}
                />
              ),
            },
          ]}
        />
      </Card>
    </>
  )
}
