import { useFormContext } from 'react-hook-form-mui'

import { AutocompleteElement } from 'react-hook-form-mui'
import { makeAutocompleteElementProps } from '~/core/forms/AutocompleteElementOptions'
import useCompanyOptions from './useCompanyOptions'
import useSWR from 'swr'
import client from '../../client'
import { formatPageQuery } from '../../client/usePageQuery'

const RepCompaniesAutocomplete = props => {
  const { data, options } = useCompanyOptions({
    companyType: 'repCompany',
    status: 'active',
  })
  return (
    <AutocompleteElement
      // required
      name="repCompanyId"
      // options={options}
      label="Rep company"
      {...makeAutocompleteElementProps(data, {
        idKey: 'companyId',
        labelKey: 'companyName',
      })}
      {...props}
    />
  )
}

const DistributorCompaniesAutocomplete = props => {
  // const { data, options } = useCompanyOptions({
  //   companyType: 'distributorCompany',
  //   status: 'active',
  // })
  const { data } = useSWR(
    // formatPageQuery('company-options', -1, undefined, undefined, {
    //   companyType: 'distributorCompany',
    //   status: 'active',
    // }),
    `company-options?search=${encodeURIComponent(
      JSON.stringify({ companyType: 'distributorCompany' })
    )}`,
    client.fetcher
  )
  return (
    <AutocompleteElement
      // required
      name="distributorCompanyId"
      // options={options}
      label="Parent distributor company "
      {...makeAutocompleteElementProps(data, {
        idKey: 'companyId',
        labelKey: 'companyName',
      })}
      {...props}
    />
  )
}

const DistributorBranchesAutocomplete = props => {
  const { data } = useSWR(
    `company-options?search=${encodeURIComponent(
      JSON.stringify({ companyType: 'distributor' })
    )}`,
    client.fetcher
  )
  return (
    <AutocompleteElement
      required
      name="distributorIds"
      multiple
      label="Connected branches"
      {...makeAutocompleteElementProps(data, {
        idKey: 'companyId',
        labelKey: 'companyName',
      })}
      {...props}
    />
  )
}

export default function AddCompanyParentSelect({
  companyType: propsCompanyType,
  ...props
}) {
  const { watch } = useFormContext()
  const formCompanyType = watch('companyType')
  const formDistributorCompanyId = watch('distributorCompanyId')
  console.log({ formDistributorCompanyId })

  const companyType = propsCompanyType || formCompanyType

  return (
    <>
      {['contractingFirm', 'distributor'].includes(companyType) &&
        client.hasRoles([
          'contract_firm_admin',
          'contractor',
          'distributor_admin',
          'distributor_user',
          'distributor_company_admin',
          'distributor_company_user',
        ]) && <RepCompaniesAutocomplete {...props} />}
      {/* https://motif.atlassian.net/jira/software/projects/UNICO/boards/2?selectedIssue=UNICO-332 */}
      {/* TODO hide for distro admins because they'll only be one option */}
      {companyType === 'distributor' &&
        client.hasRoles(['rep_admin', 'rep_sales_manager']) && (
          <DistributorCompaniesAutocomplete {...props} />
        )}

      {companyType === 'contractingFirm' &&
        client.hasRoles([
          'distributor_company_admin',
          'distributor_company_user',
        ]) && <DistributorBranchesAutocomplete {...props} />}
    </>
  )
}
